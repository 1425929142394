import CCMapper from '../CCMapper';

function CanvasMapper() {
  CCMapper.apply(this, arguments);
  this.name = 'CanvasMapper';
}

CanvasMapper.prototype = Object.create(CCMapper.prototype);
CanvasMapper.prototype.constructor = CanvasMapper;

CanvasMapper.prototype.mapSettings = function(t, _, settings) {
  CCMapper.prototype.mapSettings.apply(this, arguments);
  t.pattern = this.options.portrait ? 'random_portrait' : 'random_landscape';
  t.patterns[t.pattern].pinnable = settings.pinnable;
  t.patterns[t.pattern].favourite = settings.favourite;
  return this;
};

export default CanvasMapper;
