import { Lightning } from '@lightningjs/sdk'
import qrcode from 'qrcode'

import {
  CCBox,
  CCImageBox,
  CCTextBox,
  CCFontLoader,
} from 'private/cc'
import log from './log'

export default class Qr extends Lightning.Component {
  constructor(stage, props) {
    super(stage, props)
    if (props.qr) {
      this.printCode(props.qr)
    }
  }



  showQr(qrImage) {
    // this.htmlBg = new CCImageBox('bgimg', { classes: 'abs wrap', image: {
    //   url: './static/socialcanvas/crypto/qr.svg',
    //   size: 'cover'
    // } }).createNode().appendTo(document.body)
    this.htmlQr = new CCBox('qr', { classes: 'abs wrap' }).createNode().appendTo(document.body)
    const Bg = new CCBox('Bg', {
      background: '#000000'
    }, this.htmlQr).createNode().appendTo(this.htmlQr)

    const p = window.innerWidth < window.innerHeight
    const framePadding = p ? '6.7vh' : '12vh'

    const Frame = new CCBox('Frame', {
      top: framePadding,
      left: framePadding,
      bottom: framePadding,
      right: framePadding,
      background: '#000000',
      classes: 'abs',
      style: 'border: 1px solid rgba(255,255,255,0.2)'
    }, Bg).createNode().appendTo(Bg)

    const LogoWrap = new CCBox('LogoWrap', {
      width: p ? '50vw' : '27vw',
      height: p ? '5.4vh' : '9.8vh',
      top: p ? '-2.7vh' : '-4.9vh',
      left: p ? 'center' : ['9vh', '1.2vw'],
      style: 'abs',
      background: '#000000',
    }, Frame).createNode().appendTo(Frame)

    const SmartPhone = new CCImageBox('SmartPhone', {
      right: '2vw',
      image: {
        url: './static/socialcanvas/crypto/sp.png',
        size: 'contain',
        position: 'right bottom'
      }
    }, Bg).createNode().appendTo(Bg)

    const Logo = new CCImageBox('Logo', {
      width: p ? '48vw' : '90%',
      height: p ? '3.5vh' : '7.5vh',
      left: 'center',
      top: 'center',
      classes: 'abs',
      image: {
        url: './static/socialcanvas/crypto/logo.svg',
        width: 462,
        height: 82,
        size: 'contain',
      },
    }, LogoWrap).createNode().appendTo(LogoWrap)

    const contentPadding = p ? '2.6vh' : '9vh'
    const Content = new CCBox('Content', {
      top: contentPadding,
      left: contentPadding,
      bottom: contentPadding,
      right: contentPadding
    }, Frame).createNode().appendTo(Frame)

    const qrRadius = Math.round(0.015 * (p ? window.innerHeight : window.innerWidth));
    const QrWrap = new CCBox('QrWrap', {
      width: p ? '26vh' : '34.8vh',
      left: p ? 'center': undefined,
      right: p ? undefined : '23.2vh',
      height: p ? '26vh' : '34.8vh',
      top: p ? '5.2vh' : '29.9vh',
      style: 'border-radius: 5vh; overflow: hidden;'
    }, SmartPhone).createNode().appendTo(SmartPhone)

    const qrPadding = p ? '0.52vh' : '5%';
    const QrImage = new CCImageBox('QrImage', {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      image: {
        url: qrImage,
        width: 100,
        height: 100,
        size: 'cover'
      }
    }, QrWrap).createNode().appendTo(QrWrap)

    const Hr = new CCBox('Hr', {
      height: p ? 1 : '20.4vw',
      top: p ? [QrWrap, '5.2vh'] : 'center',
      width: p ? '26vh' : 1,
      left: p ? 'center' : [QrWrap, '5.9vw'],
      background: '#56667e'
    }, Content).createNode().appendTo(Content)

    const Title = new CCTextBox('Title', {
      top: p ? [Hr, '5.2vh'] : '12vh',
      left: p ? 0 : '2.55vw',
      right: p ? 0 : '36vw',
      height: p ? '7.8vh' : '10vh',
      classes: 'abs',
      text: {
        text: 'Personalize your Canvas',
        line: 1.2,
        lines: 1,
        font: 'Indivisible-Bold',
        color: '#ffffff',
        bold: true
      }
    }, Content).createNode().appendTo(Content)

    const size = Math.round(window.innerHeight * (p ? 0.027 : 0.048));
    const PointOne = new CCBox('PointOne', {
      left: p ? 0 : '2.55vw',
      top: [Title, p ? '4vh' : '3vh'],
      width: size,
      height: size,
      background: '#D1FF05',
      style: 'border-radius: 50%;'
    }, Content).createNode().appendTo(Content)

    const PointOneLabel = new CCTextBox('PointOneLabel', {
      width: size,
      height: size,
      top: 'center',
      left: 'center',
      fit: 'height',
      style: 'line-height: 1.4;',
      text: {
        text: '1',
        size: 0.57 * size,
        //line: 1,
        align: 'center',
        font: 'Indivisible-Bold',
        color: '#000000'
      }
    }, PointOne).createNode().appendTo(PointOne)

    const PointOneTextWrap = new CCBox('PointOneTextWrap', {
      right: '35vw',
      height: p ? '6vh' : '7vh',
      top: [PointOne, p ? '-3vh' : '-3.2vh', -size/2],
      left: [PointOne, 35]
    }, Content).createNode().appendTo(Content)
    const PointOneText = new CCTextBox('PointOneText', {
      height: '100%',
      width: '100%',
      fit: 'height',
      top: 'center',
      text: {
        text: 'Open or download Telegram.',
        line: 1.2,
        lines: p ? 3 : 2,
        maximum: 0.6 * size,
        font: 'Indivisible-Regular',
        color: '#ffffff'
      }
    }, PointOneTextWrap).createNode().appendTo(PointOneTextWrap);
    PointOneText.node.innerHTML = 'Open or download Telegram.'

    const PointTwo = new CCBox('PointTwo', {
      left: p ? 0 : '2.55vw',
      top: [PointOne, p ? '4vh' : '4.5vh'],
      width: size,
      height: size,
      background: '#D1FF05',
      style: 'border-radius: 50%;'
    }, Content).createNode().appendTo(Content)

    const PointTwoLabel = new CCTextBox('PointTwoLabel', {
      width: size,
      height: size,
      top: 'center',
      left: 'center',
      fit: 'height',
      style: 'line-height: 1.4;',
      text: {
        text: '2',
        size: 0.57 * size,
        //line: 1,
        align: 'center',
        font: 'Indivisible-Bold',
        color: '#000000'
      }
    }, PointTwo).createNode().appendTo(PointTwo)

    const PointTwoTextWrap = new CCBox('PointTwoTextWrap', {
      right: '35vw',
      height: p ? '6vh' : '7vh',
      top: [PointTwo, p ? '-3vh' : '-3.2vh', -size/2],
      left: [PointTwo, 35],
    }, Content).createNode().appendTo(Content)
    const PointTwoText = new CCTextBox('PointTwoText', {
      height: '100%',
      width: '100%',
      fit: 'height',
      top: 'center',
      text: {
        text: 'Search "CryptoCanvas" and scan this QR code to control your Canvas.',
        line: 1.2,
        lines: p ? 3 : 2,
        maximum: PointOneText.textInfo.fontSize,
        font: 'Indivisible-Regular',
        color: '#ffffff'
      }
    }, PointTwoTextWrap).createNode().appendTo(PointTwoTextWrap)
    PointTwoText.node.innerHTML = 'Search <b>"CryptoCanvas"</b><br/>and scan this QR code to control your Canvas.'

    const PointThree = new CCBox('PointThree', {
      left: p ? 0 : '2.55vw',
      top: [PointTwo, p ? '4vh' : '4.5vh'],
      width: size,
      height: size,
      background: '#D1FF05',
      style: 'border-radius: 50%;'
    }, Content).createNode().appendTo(Content)

    const PointThreeLabel = new CCTextBox('PointThreeLabel', {
      width: size,
      height: size,
      top: 'center',
      left: 'center',
      fit: 'height',
      style: 'line-height: 1.4;',
      text: {
        text: '3',
        size: 0.57 * size,
        //line: 1,
        align: 'center',
        font: 'Indivisible-Bold',
        color: '#000000'
      }
    }, PointThree).createNode().appendTo(PointThree)

    const PointThreeTextWrap = new CCBox('PointThreeTextWrap', {
      right: '35vw',
      height: p ? '6vh' : '7vh',
      top: [PointThree, p ? '-3vh' : '-3.2vh', -size/2],
      left: [PointThree, 35],
    }, Content).createNode().appendTo(Content)
    const PointThreeText = new CCTextBox('PointThreeText', {
      height: '100%',
      width: '100%',
      fit: 'height',
      top: 'center',
      text: {
        text: 'Personalize your Canvas.',
        line: 1.2,
        lines: p ? 3 : 2,
        maximum: PointOneText.textInfo.fontSize,
        font: 'Indivisible-Regular',
        color: '#ffffff'
      }
    }, PointThreeTextWrap).createNode().appendTo(PointThreeTextWrap)

    const HelpInfo = new CCTextBox('HelpInfo', {
      height: 'auto',
      width: '50vw',
      bottom: '2vh',
      left: '2.5vh',
      style: 'opacity: 0.55',
      text: {
        text: 'Message @CryptoCanvas on Telegram or email support@cryptocanvas.tv',
        line: 1.2,
        size: '2vh',
        font: 'Indivisible-Regular',
        color: '#ffffff'
      }
    }, Frame).createNode().appendTo(Frame)
    HelpInfo.node.innerHTML = 'Message <b>@CryptoCanvas</b> on Telegram or email <b>support@cryptocanvas.tv</b>';

    const Help = new CCTextBox('Help', {
      height: 'auto',
      width: '50vw',
      bottom: '5vh',
      left: '2.5vh',
      text: {
        text: 'Need Help?',
        line: 1.2,
        size: '1.9vh',
        font: 'Indivisible-Bold',
        color: '#D1FF05'
      }
    }, Frame).createNode().appendTo(Frame)

    // new CCBox('version-tag', {
    //   bottom: 10,
    //   height: 'auto',
    //   width: '100%',
    //   content: '1.0.9',
    //   style: 'font-size: 20px; color: white; text-align: center; opacity: 0.5;'
    // }, Bg).createNode().appendTo(Bg)
  }

  async printCode(code) {
    log('Qr.printCode', code)
    if (code) {
      const qrImage = await qrcode.toDataURL(code)
      const fontLoader = new CCFontLoader()
      fontLoader.preloadMultiple([
        { name: 'Thicccboi-ExtraBold', path: '../static/fonts/' },
        { name: 'Thicccboi-Medium', path: '../static/fonts/' },
        { name: 'Indivisible-Bold', path: '../static/fonts/' },
        { name: 'Indivisible-Regular', path: '../static/fonts/' },
        { name: 'Indivisible-SemiBold', path: '../static/fonts/' },
      ], () => this.showQr(qrImage))
    }
  }

  hide() {
    if (this.htmlQr) {
      this.htmlQr.remove();
      delete this.htmlQr;
    }
  }


  static _template() {
    return {
      w: window.innerWidth,
      h: window.innerHeight,
      color: '0x00000000',
      rect: true,
      clipping: true,
    };
  }
}
