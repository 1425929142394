import { Lightning } from '@lightningjs/sdk';
import CCApp from './CCApp';
import Qr from './Qr';
import realtime from './Realtime'
import { defaultProfile, defaultScreen, getCode, setCode } from './utils';
import { showLoader } from './loader';

export default class App extends Lightning.Component {
  _init() {
    console.log('_init [VERSION]: 1.0.9')
    window.addEventListener('message', (event) => {
      if (event) {
        if (event.data) {
          if (event.data === 'Back') {
            this.handleBack()
          }
          if (event.data === 'Enter') {
            this.handleEnter()
          }
        }
      }
    }, false)
  }

  handleBack() {
    if (this.Qr) {
      this.hideQr()
      realtime.unsubscribeAll()
      this.showDefault()
    }
  }

  handleEnter() {
    if (this.screenId === defaultScreen && this.Screen) {
      this.hideScreen()
      realtime.unsubscribeAll()
      this.showQr()
    }
  }

  _setup() {
    showLoader()
    if (window.location.hash && window.location.hash.slice(1).length === 36) {
      setCode(window.location.hash.slice(1))
    }
    const [existing] = getCode()
    if (existing) {
      realtime.fetchScreen(existing, (data) => {
        console.log('FETCHED', data)
        if (data && data.length && data[0] && data[0]['profile_id'] && !data[0]['deleted_at']) {
          this.profileId = data[0]['profile_id']
          this.screenId = existing
          this.showScreen()
        } else {
          this.showDefault()
        }
      });
    } else {
      this.showDefault()
    }
  }

  showDefault() {
    realtime.fetchScreen(defaultScreen, (data) => {
      if (data && data.length && data[0] && data[0]['profile_id']) {
        this.profileId = defaultProfile
        this.screenId = defaultScreen
        this.showScreen(true)
      } else {
        this.showQr()
      }
    })
  }

  showScreen(isDefault) {
    if (this.profileId && this.screenId) {
      this.hideScreen()
      this.Screen = new CCApp(this.stage, {
        isDefault,
        screenId: this.screenId,
        profileId: this.profileId
      })
      if (!isDefault) {
        realtime.subscribeScreenRemove(this.screenId, (payload) => {
          console.log('SCREEN EVENT', payload);
          if (payload && (payload.eventType === 'UPDATE' && payload.new && !!payload.new.deleted_at || payload.eventType === 'DELETED')) {
            console.log('SCREEN REMOVED');
            this.hideScreen()
            realtime.unsubscribeAll()
            this.showQr()
          }
        })
      }
    }
  }

  hideScreen() {
    if (this.Screen && this.Screen.hide) {
      this.Screen.hide()
      delete this.Screen
    }
  }

  showQr() {
    const [existing, generated] = getCode()
    const code = existing || generated
    this.hideQr()
    this.Qr = new Qr(this.stage, { qr: code })
    realtime.subscribeScreenAdd(code, (payload) => {
      console.log('SCREEN EVENT', payload)
      if (payload && (payload.eventType === 'INSERT' || payload.eventType === 'UPDATE') && !payload.new['deleted_at']) {
        const profileId = payload && payload.new && payload.new.profile_id
        if (profileId) {
          console.log('SCREEN ADDED');
          this.hideQr()
          this.profileId = profileId
          this.screenId = code
          this.showScreen()
        }
      }
    })
    window.CAN_GO_BACK = true
  }

  hideQr() {
    if (this.Qr && this.Qr.hide) {
      this.Qr.hide()
      delete this.Qr
    }
    window.CAN_GO_BACK = false
  }
}
