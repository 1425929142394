import CCCollection from '../CCCollection';

function CanvasCollection() {
  CCCollection.apply(this, arguments);
  this.name = 'CanvasCollection';
}

CanvasCollection.prototype = Object.create(CCCollection.prototype);
CanvasCollection.prototype.constructor = CanvasCollection;

CanvasCollection.prototype.hasNew = function () {
  let count = 0;
  for (let item of this.items) {
    if (item.added) count++;
  }
  return count;
}

CanvasCollection.prototype.getOld = function () {
  return this.removed && this.removed.pop();
}

CanvasCollection.prototype.hasOld = function () {
  return this.removed && this.removed.length;
}

export default CanvasCollection;
