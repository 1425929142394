import log from './log'
import { CCLoader } from 'private/cc'
import { compressHistory } from './coinUtils'
const { createClient } = supabase

function hash(table, select, eq, eqValue) {
  return `from:${table};select:${select};where:${eq}=${eqValue}`
}

function store(id, data) {
  if (window && window.localStorage) {
    window.localStorage.setItem(id, JSON.stringify(data))
  }
}

function restore(id) {
  if (window && window.localStorage) {
    const item = window.localStorage.getItem(id)
    if (item) {
      try {
        const data = JSON.parse(item)
        if (data) return data
      } catch (err) {
        console.log('failed to parse', item)
      }
    }
  }
}

function supabaseFetch(client, table, select, eq, eqValue, cb = eq, ignoreCache) {
  if (!eqValue) {
    return client
      .from(table)
      .select(select)
      .then(({ data }) => {
        store(hash(table, select), data)
        if (cb) cb(data)
      })
      .catch((err) => {
        console.log('fetch error', err)
        // if (ignoreCache) return cb && cb()
        const data = restore(hash(table, select))
        if (data && cb) cb(data)
      })
  }
  return client
    .from(table)
    .select(select)
    .eq(eq, eqValue)
    .then(({ data }) => {
      compressHistory(data)
      store(hash(table, select, eq, eqValue), data)
      if (cb) cb(data)
    })
    .catch((err) => {
      console.log('fetch error', err)
      // if (ignoreCache) return cb && cb()
      const data = restore(hash(table, select, eq, eqValue))
      if (data && cb) cb(data)
    })
}

function supabaseSubscribe(channel, op, table, filter, cb) {
  return channel
    .on(
      'postgres_changes',
      {
        event: op,
        schema: 'public',
        table: table,
        filter: filter,
      },
      (payload) => {
        console.log('RTE', payload)
        cb && cb(payload)
      }
    )
}

export async function getSource(screenId, sourceUrl, sourceName, method = 'GET') {
  const url = (document.domain === 'localhost') ?
    `http://localhost:3000/proxy?url=${sourceUrl}`: sourceUrl
  return new Promise((resolve, reject) => {
    new CCLoader(sourceName, {
      method,
      url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJzc2FsY3h2Zmhsam1kcmRyY3llIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTQ3MTg2MTEsImV4cCI6MjAxMDI5NDYxMX0.Gh343g3SJrOsaYFA0p4nxkY7iAJMvpCDDiHwssGn4oc'
      },
      query: { screen_id: screenId }
    }).load((data) => {
      console.log('LOADED',data)
      compressHistory(data)
      store(`${screenId}_${sourceName}`, data)
      resolve(data)
    }, (err) => {
      console.log('fetch error', err)
      const data = restore(`${screenId}_${sourceName}`)
      if (data) resolve(data)
      else reject(err)
    });
  });
}

class Realtime {
  constructor() {
    this.client = createClient(
      'https://rssalcxvfhljmdrdrcye.supabase.co',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJzc2FsY3h2Zmhsam1kcmRyY3llIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTQ3MTg2MTEsImV4cCI6MjAxMDI5NDYxMX0.Gh343g3SJrOsaYFA0p4nxkY7iAJMvpCDDiHwssGn4oc'
    )
  }

  subscribeAll() {
    this.unsubscribeAll()
    console.log('subscribeAll')
    if (this.screenId && this.coins) {
      const channel = this.client.channel('changes')
      supabaseSubscribe(channel, 'UPDATE', 'assets', `id=in.(${this.coins.join(', ')})`, this.onAssetsUpdated)
      if (this.profileId) {
        supabaseSubscribe(channel, '*', 'profile_assets', `profile_id=eq.${this.profileId}`, this.onCoinsChanged)
      } else {
        supabaseSubscribe(channel, '*', 'assets', `trending=eq.true`, this.onCoinsChanged)
      }
      supabaseSubscribe(channel, 'UPDATE', 'screen_settings', `screen_id=eq.${this.screenId}`, this.onSettingsUpdated)
      supabaseSubscribe(channel, '*', 'profile_screen', `screen_id=eq.${this.screenId}`, this.onScreenRemoved)
      this.masterSubs = channel.subscribe()
    }
  }

  unsubscribeAll() {
    if (this.masterSubs) {
      this.masterSubs.unsubscribe()
      delete this.masterSubs
    }
  }

  subscribeCoins(coins = [], cb, force = true) {
    log('Realtime.subscribeCoins', coins)
    console.log('SUBSCRIBE COINS UPDATES', coins)
    this.coins = coins
    this.onAssetsUpdated = cb
    return force && this.subscribeAll()
  }

  subscribeCoinsList(profile_id, cb, force = true) {
    log('Realtime.subscribeCoinsList', profile_id)
    console.log('SUBSCRIBE COINS LIST', profile_id)
    this.profileId = profile_id
    this.onCoinsChanged = cb
    return force && this.subscribeAll()
  }

  subscribeTrendingList(cb, force = true) {
    log('Realtime.subscribeTrendingList')
    console.log('SUBSCRIBE TRENDING LIST')
    this.profileId = null
    this.onCoinsChanged = cb
    return force && this.subscribeAll()
  }

  subscribeSettings(uuid, cb, force = true) {
    log('Realtime.subscribeSettings', uuid)
    this.screenId = uuid
    this.onSettingsUpdated = cb
    return force && this.subscribeAll()
  }

  fetchAll(uuid, cb) {
    supabaseFetch(
      this.client,
      'profile_screen',
      `
        *,
        profile!inner(
          *,
          profile_assets!inner(
            *,
            assets!inner(*)
          )
        ),
        screen_settings!inner(*)
      `,
      'screen_id',
      uuid,
      cb
    )
  }

  fetchTrending(cb) {
    supabaseFetch(
      this.client,
      'assets',
      '*',
      'trending',
      'true',
      cb
    )
  }

  fetchAssets(profile_id, cb) {
    supabaseFetch(
      this.client,
      'profile_assets',
      `
        *,
        assets!inner(*)
      `,
      'profile_id',
      profile_id,
      cb
    )
  }

  fetchStats(cb) {
    Promise.all([
      new Promise(resolve => supabaseFetch(this.client, 'global_stats', '*', resolve)),
      new Promise(resolve => supabaseFetch(this.client, 'assets', '*', 'symbol', 'BTC', resolve)),
    ]).then(function (data) {
      if (data && data.length) {
        console.log(data);
        const result = [];
        result.push({
          id: 1,
          created_at: new Date().toISOString(),
          bitcoin_market_cap: data[1][0]['usd_market_cap'],
          bitcoin_volume: data[1][0]['volume_usd_24hr'],
          bitcoin_market_cap_change: data[1][0]['usd_market_cap_change_percent_24hr'],
          bitcoin_dominance: data[0][0]['bitcoin_dominance_percent']
        })
        result.push({
          id: 1,
          created_at: new Date().toISOString(),
          bitcoin_market_cap: data[1][0]['usd_market_cap'],
          bitcoin_market_cap_change: data[1][0]['usd_market_cap_change_percent_24hr'],
          altcoin_market_cap: (data[0][0]['total_market_cap'] / 100) * (100 - data[0][0]['bitcoin_dominance_percent']),
          altcoin_market_cap_change: -1 * data[1][0]['usd_market_cap_change_percent_24hr'],
          global_market_cap: data[0][0]['total_market_cap'],
          global_market_cap_change: data[0][0]['total_market_cap_24hr_percent'],
        })
        cb(result)
      }
    }).catch(function (err) {
      console.log('Failed to fetch Stats', err)
    })
  }

  fetchMarketCap(cb) {
    cb([{
      id: 1,
      created_at: new Date().toISOString(),
      global_market_cap: 1000000000,
      bitcoin_market_cap: 1000000000,
      altcoin_market_cap: 238000000000,
    }])
  }

  fetchSettings(uuid, cb) {
    supabaseFetch(
      this.client,
      'screen_settings',
      '*',
      'screen_id',
      uuid,
      cb
    )
  }

  fetchScreen(uuid, cb, ignoreCache) {
    supabaseFetch(
      this.client,
      'profile_screen',
      '*',
      'screen_id',
      uuid,
      cb,
      ignoreCache
    )
  }

  subscribeScreenAdd(uuid, cb) {
    log('Realtime.subscribeScreenAdd', uuid)
    console.log('subscribeScreenAdd', uuid)
    this.unsubscribeAll()
    supabaseSubscribe(
      this.client.channel('changes'),
      '*',
      'profile_screen',
      `screen_id=eq.${uuid}`,
      this.onScreenAdded = cb
    ).subscribe()
  }

  subscribeScreenRemove(uuid, cb) {
    log('Realtime.subscribeScreenRemove', uuid)
    console.log('subscribeScreenRemove', uuid)
    this.unsubscribeAll()
    supabaseSubscribe(
      this.client.channel('changes'),
      '*',
      'profile_screen',
      `screen_id=eq.${uuid}`,
      this.onScreenRemoved = cb
    ).subscribe()
  }
}

const realtime = new Realtime();

export default realtime;
