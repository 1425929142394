import { v4 as uuidv4 } from 'uuid'

const defaultScreen = 'b2dae8dc-59c5-4bce-a0e3-acb64041d098'
const defaultProfile = '37f63a78-ba36-4a9a-b196-9fcaa8600cda'

function getCode() {
  let existing, generated
  if (window.localStorage) {
    existing = window.localStorage.getItem('uuid')
  }
  if (!existing) {
    generated = uuidv4()
    if (window.localStorage) {
      window.localStorage.setItem('uuid', generated)
    }
  }
  return [existing, generated]
}

function setCode(code) {
  if (window.localStorage) {
    window.localStorage.setItem('uuid', code)
  }
}

export {
  getCode,
  setCode,
  defaultScreen,
  defaultProfile
}
