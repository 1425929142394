//const SERVER_URL = 'ws://34.76.188.161:3000';
const SERVER_URL = 'https://websocket.cryptocanvas.tv';
const COINS_MAPPING = {
  "BTC": "Qwsogvtv82FCd",
  "ETH": "razxDUgYGNAdQ",
  "USDT": "HIVsRcGKkPFtW",
  "XRP": "-l8Mn2pVlRs-p",
  "BNB": "WcwrkfNI4FUAe",
  "ADA": "qzawljRxB5bYu",
  "DOGE": "a91GCGd_u96cF",
  "DOT": "f3iaFeCKEmkaZ",
  "SOL": "zNZHO_Sjf",
  "SHIB": "xz24e0BjL",
  "PEPE": "ind8fU0Yr",
  "TRX": "qUhEFk1I61atv",
  "TON": "67YlI0K1b",
  "LINK": "VLqpJwogdhHNb",
  "XLM": "f3iaFeCKEmkaZ",
  "AVAX": "dvUj0CzDZ",
  "SUI": "3xJluUMvp",
  "HBAR": "jad286TjB",
  "LTC": "D7B1x_ks7WhV5",
  "DOT": "25W7FG7om"
};

class CryptoSocket {
  constructor(serverUrl = SERVER_URL) {
    this.subscribedCoins = new Set();
    this.serverUrl = serverUrl
  }

  subscribeCoins(coins = [], cb) {
    console.log('subscribeCoins', coins)
    this.coins = coins
    this.coinsThrottle = {}
    this.coinsUpdatesCallback = cb

    if (!this.socket) {

      this.socket = new WebSocket(this.serverUrl)

      this.socket.onopen = () => {
        console.log('Socket.onopen')
      };

      this.socket.onerror = (error) => {
        console.error('Socket.onerror:', error)
      };

      this.socket.onclose = () => {
        console.log('Socket.onclose')
      };

      this.socket.onmessage = (ev) => {
        // console.log('Socket.onmessage', ev)
        if (ev && ev.data && this.coinsUpdatesCallback) {
          try {
            const data = JSON.parse(ev.data)
            if (data.type === 'cryptoData' && Array.isArray(data.data)) {
              //console.log('data.data', data.data)
              const events = []
              data.data.forEach(d => {
                for (let i in COINS_MAPPING) {
                  if (COINS_MAPPING[i] === d.currencyUuid && this.coins.includes(i)) {
                    if (!this.coinsThrottle[i] || new Date().getTime() - this.coinsThrottle[i] > 5000) {
                      events.push({ ticker: i, price: d.price })
                    }
                  }
                }
              })
              events.map(event => {
                this.coinsThrottle[event.ticker] = new Date().getTime()
                this.coinsUpdatesCallback && this.coinsUpdatesCallback(event)
              })
            }
          } catch (err) {}
        }
      }

      // this.socket = io(this.serverUrl);

      // this.socket.on("connect", () => {
      //   console.log("Connected to crypto socket server");
      // });

      // this.socket.on("disconnect", () => {
      //   console.log("Disconnected from crypto socket server");
      // });

      // this.socket.on("cryptoData", (data) => {
      //   console.log("cryptoData update:", data);
      //   if (this.coinsUpdatesCallback) {

      //   }
      // });
    }
  }
}

const socket = new CryptoSocket();

export default socket;
