import {
  CC,
  utils,
} from 'private/cc';

import CCCollection from './CCCollection';
import CCMapper from './CCMapper';

export default class CCPresentation extends CC {
  constructor(id, options) {
    super(id, options);
    this.name = 'CCPresentation';
    this._init();
  }

  _init() {
    this.app = this.options.app;
    this.device = this.app.device;
    this.stage = this.app.htmlStage;
    this.template = this.app.template;
    this.manifest = this.app.manifest;
    this.feedSettings = this.app.feedSettings;
    this.feedItems =  this.app.feedItems;

    return this
      .createTimeline()
      .createMapper()
      .mapSettings()
      .createCollection()
      .registerEvents();
  }

  registerEvents() { return this }
  onResize() { return this.resizePresentation() }
  resizePresentation() { return this.resizeSlides() }
  resizeSlides() { return this }

  onOrientationChanged() {
    location.reload();
    return this;
  }

  createTimeline() {
    this.destroyTimeline();
    this.tl = new TimelineMax({ paused: true, autoRemoveChildren: true });
    TweenMax.lagSmoothing(0);
    TweenLite.lagSmoothing(0);
    this.paused = true;
    return this;
  }

  destroyTimeline() {
    if (this.tl) {
      this.tl.stop().kill().clear();
      this.tl = null;
    }
    return this;
  }

  createMapper() {
    this.mapper = new (this.getMapperClass())('mapper', this.getMapperConfig());
    return this;
  }

  getMapperClass() {
    return CCMapper;
  }

  getMapperConfig() { return {}; }

  createCollection() {
    this.collection = new (this.getCollectionClass())('collection', this.getCollectionConfig());
    return this;
  }

  getCollectionConfig() {
    return {
      data: this.feedItems,
      shuffle: this.template.isRandom,
    };
  }

  getCollectionClass() {
    return CCCollection;
  }

  getNextItem(callback, scope, first) {
    let item;

    if (this.collection) {
      item = this.collection.getNext();
      if (!item) {
        return this.triggerEmptyCollection();
      }
      this.cleanUpItem();
      this.preloadItem(item, function () {
        if (callback) {
          callback.call(scope, item);
        }
      }, function () {
        this.cleanUpItem(item);
        this.collection.banItem(item);
        this.getNextItem(callback, scope, first);
      }, this, first);
    }
    return this;
  }

  updateMode() {
    if (this.template && this.template.performance && this.template.performance !== 'auto') {
      this.setMode(this.template.performance);
    } else {
      if (this.template && this.template.performance === 'auto') {
        this.checkFps();
        this.setMode(this.getModeByFps() || this.defaultMode || this.template.performance);
      } else {
        this.setMode(this.defaultMode);
      }
    }
    return this;
  }

  setMode(value) {
    if (value && this.mode !== value) {
      this.mode = value;
    }
    return this;
  }

  mapSettings() {
    this.mapper.mapSettings(this.template, this.manifest, this.feedSettings);
    return this;
  }

  mapItem(item) {
    this.item = this.mapper.mapItem(this.template, item, this.manifest);
    return this;
  }

  updateData() {
    this.feedSettings = this.app.feedSettings;
    this.feedItems = this.app.feedItems;
    this.mapSettings();
    this.collection.updateCollection(this.getCollectionConfig());
    return this;
  }

  updateSettings() {
    this.feedSettings = this.app.feedSettings;
    return this.mapSettings();
  }

  stop() {
    if (this.tl) {
      this.paused = true;
      this.tl.pause();
    }
    return this;
  }

  play(label) {
    if (this.tl && this.paused) {
      this.onFrame(function () {
        if (label) {
          this.tl.gotoAndStop(label);
          return;
        }
        this.tl.play();
      }, this, this.app.bypass);
    }
    return this;
  }

  getModeByFps() {
    this.defaultMode = this.defaultMode || this.device && this.device.defaultMode || 'main';
    if (this.fps) {
      if (this.defaultMode === 'main' && this.fps > 40) { return 'main'; }
      if (this.defaultMode !== 'low' && this.fps > 10) { return 'basic'; }
      return 'low';
    }
  }

  checkFps() {
    if (!this.fps) {
      utils.fps.start(4, function (fps) {
        this.fps = fps;
      }, this);
    }
    return this;
  }

  preloadItem(_, success, failed, scope) {
    return this
      .preloadManifest(success, failed, scope, this.app.bypass);
  }

  preloadManifest(success, failed, scope, bypass) {
    if (bypass) {
      this.app.loadManifestImages();
      this.app.addPixelsFromManifest(success, scope, bypass);
      return this;
    }
    this.app.loadManifestImages(function () {
      this.app.addPixelsFromManifest(success, scope);
    }, function () {
      if (failed) failed.call(scope);
    }, this);
    return this;
  }

  updatePixel(url, name, success, scope, bypass) {
    this.app.updatePixel(url, name);
    return this.onFrame(success, scope, bypass);
  }

  updatePixels(urls, name, success, scope, bypass) {
    if (urls && urls.length) {
      for (let i = 0; i < urls.length; i++) {
        this.app.updatePixel(urls[i].url, name+i);
      }
    }
    return this.onFrame(success, scope, bypass);
  }

  cleanUpItem = function () { return this; }

  triggerEmptyCollection() {
    this.app.triggerSplashScreen('noContentToDisplay');
    return this.destroy();
  }

  hideSplashScreen() {
    this.app.hideSplashScreen();
    return this;
  }

  show() {
    this.stage?.show();
    return this;
  }

  hide() {
    this.stage?.hide();
    return this;
  }

  destroy() {
    if (this.stage) {
      this.stage.node.innerHTML = '';
    }
    if (this.app) {
      this.app.started = false;
    }
    this.item = null;
    this.intro = null;
    this.slide = null;
    this.previous = null;
    this.delay = 0;
    this.feedCount = 0;
    if (this.tl) {
      this.createTimeline();
    }
    return this;
  }

  checkFonts(callback, params, scope) {
    this.app.addFont(this.item.font, this.item.fontPath, function() {
      callback.call(scope, params);
    });
    return this;
  }

  onReady() { return this.ready(); }
  getOutDelay() { return 0; }

}
