import { Lightning } from '@lightningjs/sdk'
import Presentation from './Presentation'
import realtime from './Realtime'
import { setCode } from './utils'
import log from './log'

export default class CCApp extends Lightning.Component {
  constructor(stage, props) {
    super(stage, props)
    if (props.screenId && props.profileId) {
      if (props.isDefault) {
        this.isDefault = true
        this.setScreen(props.screenId, props.profileId)
      } else {
        this.isDefault = false
        setCode(props.screenId)
        this.setScreen(props.screenId, props.profileId)
        this.setupEvents(props.screenId)
      }
    }
  }

  setupEvents(code) {
    log('CCApp.setupEvents')
    if (code && realtime) {
      realtime.subscribeSettings(code, (payload) => {
        log('App.onSettings', payload)
        console.log('App.onSettings', payload)
        if (this.app) {
          this.app.updateSettings(payload)
        }
      })
    }
  }

  hide() {
    if (this.app) {
      this.app.destroy()
      delete this.app
    }
  }

  getProxyUrl() {
    if (
      window['settings'] &&
      window['settings'].ccSettings &&
      window['settings'].ccSettings.PROXY_URL
    ) {
      return `${window['settings'].ccSettings.PROXY_URL}?url=`
    }
    return ''
  }

  getTemplate() {
    if (
      window['settings'] &&
      window['settings'].ccSettings &&
      window['settings'].ccSettings.TEMPLATE
    ) {
      return window['settings'].ccSettings.TEMPLATE;
    }
    return 'crypto';
  }

  setScreen(screenId, profileId) {
    console.log('!setScreen', screenId, profileId)
    log('CCApp.setScreen');
    window.presentation = this.app = new Presentation(this.stage, {
      w: window.innerWidth,
      h: window.innerHeight,
      color: '0x00000000',
      rect: true,
      clipping: true,
      type: Presentation,
      config: {
        screenId,
        profileId,
        isDefault: this.isDefault,
        type: this.getTemplate(),
        autoplay: true,
      },
    })
    this.childList.add(this.app)
  }

  static _template() {
    const portrait = window.innerWidth < window.innerHeight
    return {
      w: window.innerWidth,
      h: window.innerHeight,
      color: '0x00000000',
      rect: true,
      clipping: true,
      /* v1
      BgImage: {
        x: portrait ? -1 : -2,
        y: portrait ? -2 : -1,
        w: window.innerWidth + (portrait ? 2 : 4),
        h: window.innerHeight + (portrait ? 4 : 2),
        src: portrait ? 'static/socialcanvas/crypto/splash-portrait.jpg' : 'static/socialcanvas/crypto/splash.jpg'
      }*/
    }
  }
}
