import { CC, utils } from 'private/cc';

function CCCollection() {
  CC.apply(this, arguments);
  this.name = 'CCCollection';
  this._init();
}

CCCollection.prototype = Object.create(CC.prototype);
CCCollection.prototype.constructor = CCCollection;

CCCollection.prototype._init = function(orders = []) {
  let i, data;

  if (this.options.data) {
    this.items = [];
    this.removed = [];

    for (i = 0; i < this.options.data.length; i++) {
      data = this.options.data[i];

      this.setType(data);
      this.addProxy(data);
      this.addTimestamp(data);

      if (this.checkItem(data)) {
        this.items.push(data);
      }
    }

    this._addOrder();
  }

  return this;
};

CCCollection.prototype.addProxy = function() {
  return this;
};

CCCollection.prototype.addTimestamp = function() {
  return this;
};

CCCollection.prototype.setType = function(item) {
  if (!item.type) {
    item.type = 'text';
  }
  return this;
};

CCCollection.prototype.checkItem = function() {
  return this;
};

CCCollection.prototype.updateCollection = function(options, silent = false) {
  this.options = utils.clone(options);
  if (options.data) {
    if (silent) {
      this.items = [];
    } else {
      this.removed = this.removed.concat(this.items.filter(item => options.data.findIndex(d => d.id === item.id) < 0));
      this.items = this.items.filter(item => options.data.findIndex(d => d.id === item.id) >= 0);
    }
    let reshuffle = false;
    let data;
    for (const i in options.data) {
      data = options.data[i];
      this.setType(data);
      this.addTimestamp(data);
      if (!this.checkItem(data)) continue;
      if (!this.updateItem(data)) {
        reshuffle = !silent;
        data.added = !silent;
        this.items.push(data);
      }
    }
    if (reshuffle) {
      if (this.options.shuffle) {
        this.shuffle(this.items);
      } else {
        this.items = this.items.sort(function (a, b) {
          return b.timestamp - a.timestamp;
        });
      }
      this.shiftOrder();
    }
  }
  return this;
};

CCCollection.prototype.insertItem = function(item) {
  const data = item;
  if (data) {
    this.setType(data);
    if (!this.checkItem(data)) return this;
    if (this.items && !this.updateItem(data)) {
      if (this.options.shuffle) {
        this.items.unshift(data);
        this.shuffle(this.items);
      } else {
        this.items.push(data);
        this.items = this.items.sort(function (a, b) {
          return b.timestamp - a.timestamp;
        });
      }
      this.shiftOrder();
    }
  }
  return this;
};

CCCollection.prototype.updateItem = function(item) {
  var i, o, a;
  if (item && this.items) {
    for (i = 0; i < this.items.length; i++) {
      if (item.id === this.items[i].id) {
        o = this.items[i].order;
        a = this.items[i].added;
        this.items[i] = item;
        this.items[i].order = o;
        this.items[i].added = a;
        return true;
      }
    }
  }
  return false;
};

CCCollection.prototype.shiftOrder = function() {
  if (this.items && this.order) {
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].order = this.items[i].order || this.order++;
    }
  }
  return this;
};

CCCollection.prototype._addOrder = function() {
  if (!this.order) {
    this.order = 0;
  }

  if (this.options.shuffle) {
    this.shuffle(this.items);
  } else {
    this.items = this.items.sort(function(a, b) {
      return b.timestamp - a.timestamp;
    });
  }

  for (let i = 0; i < this.items.length; i++) {
    this.items[i].order = this.order;
    this.order++;
  }

  return this;
};

CCCollection.prototype.shuffle = function(items) {
  var j, x, i;
  for (i = items.length; i; i--) {
    j = Math.floor(Math.random() * i);
    x = items[i - 1];
    items[i - 1] = items[j];
    items[j] = x;
  }
  return this;
};

CCCollection.prototype.setRandom = function(random) {
  if (this.options.shuffle !== random) {
    this.options.shuffle = random;
    this._addOrder();
  }
  return this;
};

CCCollection.prototype.getNext = function() {
  if (typeof this.count === 'undefined') {
    this.count = 0;
  } else {
    this.count = this.count + 1;
  }

  let min;
  if (this.items) {
    for (let i = 0; i < this.items.length; i++) {
      if (!min || this.items[i].order < min.order) {
        min = this.items[i];
      }
    }
    if (!min && this.items.length === 1) {
      min = this.items[0];
    }
  } else {
    console.warn('collection items undefined');
    return null;
  }

  if (min) {
    min.order = ++this.order;
    return min;
  } else {
    console.warn('empty collection', this);
    return null;
  }
};

export default CCCollection;
