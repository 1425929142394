import CCPresentation from '../../CCPresentation';
import CryptoCoinsCollection from './CryptoCoinsCollection';
import CryptoCoinsMapper from './CryptoCoinsMapper';
import CryptoNewsCollection from './CryptoNewsCollection';
import CryptoNewsMapper from './CryptoNewsMapper';
import CryptoVideosCollection from './CryptoVideosCollection';
import CryptoVideosMapper from './CryptoVideosMapper';
import CryptoSponsoredCollection from './CryptoSponsoredCollection';
import CryptoSponsoredMapper from './CryptoSponsoredMapper';
import CryptoGreedIndexCollection from './CryptoGreedIndexCollection';
import CryptoGreedIndexMapper from './CryptoGreedIndexMapper';
import CryptoDominanceCollection from './CryptoDominanceCollection';
import CryptoDominanceMapper from './CryptoDominanceMapper';
import CryptoMarketCapCollection from './CryptoMarketCapCollection';
import CryptoMarketCapMapper from './CryptoMarketCapMapper';
import log from '../../../log';
import realtime from '../../../Realtime';
import socket from '../../../Socket';

import {
  CanvasHtmlMatrixBox,
  CanvasCoinBox,
  CanvasNewsBox,
  CanvasSponsoredBox,
  CanvasVideoBox,
  CanvasGreedIndexBox,
  CanvasDominanceBox,
  CanvasMarketCapBox,
  CanvasSplashBox,
  utils,
} from 'private/cc';

export default class CryptoCanvasPresentation extends CCPresentation {
  constructor(id, options) {
    super(id, options);
    this.name = 'CryptoCanvasPresentation';
  }

  _init() {
    log('CryptoCanvasPresentation._init');
    CCPresentation.prototype._init.apply(this, arguments);

    this.updateMode();

    this.slide = new CanvasHtmlMatrixBox('matrix', {
      /* v1
      left: this.stage.portrait ? -1 : -2,
      right: this.stage.portrait ? -1 : -2,
      top: this.stage.portrait ? -2 : -1,
      bottom: this.stage.portrait ? -2 : -1,
      /*/
      left: 2,
      right: 2,
      top: 2,
      bottom: 2,
      //*/
      config: this.template,
      portrait: this.stage.portrait,
      mode: this.mode,
      presentation: this
    })
      .createNode()
      .appendTo(this.stage.node);

    return this;
  }

  registerEvents() {
    log('CryptoCanvasPresentation.registerEvents');
    if (realtime) {
      if (this.collections['coin']) {
        socket.subscribeCoins(this.collections['coin'].getTickers(), (ev) => {
          const event = this.collections['coin'].handleEventSocket(ev);
          if (this.collections['coin'].handleEventSocket(ev) && this.slide) {
            this.slide.updateCoin(event.new.id, event.new.price);
          } else {
            console.log(`item ${event.ticker} not found`);
          }
        })
        // realtime.subscribeCoins(this.collections['coin'].getTickers(), (ev) => {
        //   if (ev.eventType === 'UPDATE') {
        //     if (this.collections['coin'].handleEvent(ev) && this.slide) {
        //       this.slide.updateCoin(ev.new.id, ev.new.symbol, ev.new.price, ev.old.price);
        //     } else {
        //       console.log(`item ${ev.new.symbol} not found`);
        //     }
        //   }
        // }, false)
        if (this.app.feedSettings.trending) {
          realtime.subscribeTrendingList((ev) => {
            if (ev && (ev.eventType === 'INSERT' || ev.eventType === 'DELETE')) {
              console.log('TrendingCoinsListEvent', ev);
              // this.app.loadAssets()
            }
          })
        } else {
          realtime.subscribeCoinsList(this.app.profileId, (ev) => {
            console.log('!COINS LIST UPDATE', ev);
            if (ev && (ev.eventType === 'INSERT' || ev.eventType === 'DELETE' || ev.eventType === 'UPDATE')) {
              console.log('OwnCoinsListEvent', ev);
              this.app.loadAssets();
            }
          })
        }
      }
    }
    return this;
  }

  createCollection() {
    log('CryptoCanvasPresentation.createCollection');
    this.collections = {
      'coin': CryptoCoinsCollection,
      'favourite': CryptoCoinsCollection,
      'news': CryptoNewsCollection,
      'video': CryptoVideosCollection,
      'sponsored': CryptoSponsoredCollection,
      'greed-index': CryptoGreedIndexCollection,
      'dominance': CryptoDominanceCollection,
      'market-cap': CryptoMarketCapCollection,
    };
    for (let c in this.collections) {
      this.collections[c] = new this.collections[c](`${c}-collection`, {
        data: this.app.feedItems[c],
        shuffle: this.template.random
      });
      this.template.collections[c] = this.collections[c].items.length;
    }

    return this;
  }

  createMapper() {
    log('CryptoCanvasPresentation.createMapper');
    this.mappers = {
      'coin': CryptoCoinsMapper,
      'favourite': CryptoCoinsMapper,
      'news': CryptoNewsMapper,
      'video': CryptoVideosMapper,
      'sponsored': CryptoSponsoredMapper,
      'greed-index': CryptoGreedIndexMapper,
      'dominance': CryptoDominanceMapper,
      'market-cap': CryptoMarketCapMapper,
    };
    for (let m in this.mappers) {
      this.mappers[m] = new this.mappers[m](`${m}-mapper`, {
        portrait: this.stage.portrait,
        template: this.template,
        manifest: this.manifest,
      });
    }
    return this;
  }

  mapSettings() {
    log('CryptoCanvasPresentation.mapSettings', this.template);
    for (let m in this.mappers) {
      this.mappers[m].mapSettings(this.template, this.manifest, this.app.feedSettings);
    }
    this.template.patterns[this.template.pattern].allowed = this.app.feedSettings.allowed;
    this.template.patterns[this.template.pattern].random = this.app.feedSettings.random;
    this.template.patterns[this.template.pattern].pinnable = this.app.feedSettings.pinnable;
    this.template.patterns[this.template.pattern].favourite = this.app.feedSettings.favourite;
    return this;
  }

  mapItem(item) {
    log('CryptoCanvasPresentation.mapItem');
    this.item = this.getItem(item);
    return this;
  }

  getItem(item) {
    log('CryptoCanvasPresentation.getItem');
    if (item.type === 'splash') return item;
    if (item.type === 'cta') return item;
    return this.mappers[item.type].mapItem(this.template, item, this.manifest);
  }

  start() {
    log('CryptoCanvasPresentation.start');
    this.item = utils.clone(this.template);

    this.checkFonts(function () {
      if (this.slide) {
        this.slide.process(this.onReady, this);
      }
    }, null, this);

    return this;
  }

  getNextFromCollection(type, categories = [], ids = []) {
    log('CryptoCanvasPresentation.getNextFromCollection');
    if (type && this.collections[type]) {
      return this.collections[type].getNext(categories, ids);
    }
  }

  getNextItem(callback, scope, type, categories, ids = []) {
    log('CryptoCanvasPresentation.getNextItem');
    if (
      this.collections['coin'] &&
      this.collections['news'] &&
      this.collections['video'] &&
      this.collections['favourite'] &&
      this.collections['greed-index'] &&
      this.collections['dominance'] &&
      this.collections['market-cap'] &&
      this.collections['sponsored']
    ) {
      let item;
      if (!type) {
        type = categories?.[0] || 'coin';
      }
      item = this.collections[type].getNext(categories, ids);
      if (!item) {
        if (type === 'coin') {
          callback.call(scope, { type: 'splash', url: './static/socialcanvas/crypto/placeholder.svg', empty: true });
        }
        return this;
      }
      this.cleanUpItem();
      this.preloadItem(
        item,
        function() {
          if (callback) {
            callback.call(scope, item);
          }
        },
        function() {
          this.cleanUpItem(item);
          this.getNextItem(callback, scope, type, categories, ids);
        },
        this
      );
    }
    return this;
  }

  preloadItem(item, success, failed, scope, first) {
    log('CryptoCanvasPresentation.preloadItem');
    if (item.type === 'coin') {
      this.preloadManifest(success, failed, scope);
      // this.loadMedia(
      //   item, 'logo', 'iconUrl',
      //   function () {
      //     this.updatePixel(item.logo.url, 'logo', function () {
      //       this.preloadManifest(success, failed, scope, first && this.app.bypass);
      //     }, this, first && this.app.bypass);
      //   },
      //   function () {
      //     if (failed) {
      //       failed.call(scope)
      //     }
      //   },
      //   this
      // );
    } else if (item.type === 'sponsored') {
      this.loadMedia(
        item, 'image', 'imageUrl',
        function () {
          this.updatePixel(item.image.url, 'image', function () {
            this.preloadManifest(success, failed, scope, first && this.app.bypass);
          }, this, first && this.app.bypass);
        },
        function () {
          if (failed) {
            failed.call(scope)
          }
        },
        this
      );
    } else {
      this.preloadManifest(success, failed, scope);
    }
    return this;
  }

  cleanUpItem(item) {
    log('CryptoCanvasPresentation.cleanUpItem');
    return this
      .cleanMedia(item?.image)
      .cleanMedia(item?.logo);
  }

  cleanMedia(media) {
    log('CryptoCanvasPresentation.cleanMedia');
    if (media?.img) {
      delete media.img;
      media.img = null;
      media.loaded = false;
    }
    return this;
  }

  loadMedia(item, name, path, success, failed, scope) {
    log('CryptoCanvasPresentation.loadMedia');
    if (!item[name]) {
      item[name] = {
        url: item[path],
        required: true
      };
    }
    if (item[name].url) {
      item[name].required = true;
      this.app.imageLoader.preload(
        item[name],
        function() {
          if (item[name].img) {
            success.call(scope);
          } else if (failed) {
            failed.call(scope);
          }
        },
        this
      );
    } else if (failed) {
      failed.call(scope);
    }
    return this;
  }

  play() {
    log('CryptoCanvasPresentation.play');
    if (this.slide) {
      this.onFrame(function () {
        this.slide.processTimeline.play();
      }, this, this.app.bypass);
    }
    return this;
  }

  getItemClass(type) {
    log('CryptoCanvasPresentation.getItemClass');
    switch (type) {
      case 'coin':
        return CanvasCoinBox;
      case 'news':
        return CanvasNewsBox;
      case 'video':
        return CanvasVideoBox;
      case 'sponsored':
        return CanvasSponsoredBox;
      case 'favourite':
        return CanvasCoinBox;
      case 'greed-index':
        return CanvasGreedIndexBox;
      case 'dominance':
        return CanvasDominanceBox;
      case 'market-cap':
        return CanvasMarketCapBox;
      case 'cta':
      case 'splash':
        return CanvasSplashBox;
      default:
        return CanvasCoinBox;
    };
  }

  createItem(item, width, height) {
    log('CryptoCanvasPresentation.createItem');
    if (item) {
      return new (this.getItemClass(item.type))(undefined, {
        width: width,
        height: height,
        portrait: this.stage.portrait,
        config: this.getItem(item)
      })._getNodeTemplate();
    }
    return '';
  }

  onOrientationChanged() {
    log('CryptoCanvasPresentation.onOrientationChanged');
    if (this.slide) {
      this.slide
        .updateScreen(this.stage)
        .setOrientation(this.stage.portrait);
    }
    return this;
  }

  onResize() {
    log('CryptoCanvasPresentation.onResize');
    if (this.slide) {
      this.slide
        .updateScreen(this.stage)
        .resize();
    }
    return this;
  }

  setMode() {
    log('CryptoCanvasPresentation.setMode');
    CCPresentation.prototype.setMode.apply(this, arguments);
    if (this.slide) {
      this.slide.setMode(this.mode);
    }
    return this;
  }

  resizeNode(node, font, width, height) {
    log('CryptoCanvasPresentation.resizeNode');
    const feed = node.querySelector('.sc-feed');
    if (feed) {
      this.getItemClass('feed').prototype.resizeNode(feed, font, width, height);
    }
    return this;
  }

  updateSettings() {
    log('CryptoCanvasPresentation.updateSettings');
    CCPresentation.prototype.updateSettings.apply(this, arguments);
    if (this.slide) {
      this.slide.updateMatrixConfig();
    }
    return this;
  }

  updateCollection(name, data, silent = false) {
    log('CryptoCanvasPresentation.updateCollection');
    if (this.collections && this.collections[name]) {
      this.collections[name].updateCollection({
        data,
        shuffle: this.template.random
      }, silent);
      this.template.collections[name] = this.collections[name].items.length;
      if (silent) {
        if (this.slide) {
          this.slide.shouldRefresh();
        }
      } else if (name === 'coin') {
        if (this.slide && this.collections[name].hasNew) {
          this.slide.shouldShowNext(this.collections[name].hasNew());
        }
        if (this.slide && this.collections[name].hasOld) {
          this.slide.shouldCleanUp(this.collections[name].hasOld());
        }
      } else if (
        name === 'greed-index' ||
        name === 'dominance' ||
        name === 'market-cap'
      ) {
        if (this.slide && this.collections[name].hasNew) {
          this.collections[name].hasNew() &&
            this.slide.shouldRotatePinnable(name);
        }
      }
    }
    return this;
  }

  getUpdated(name, ids, ts) {
    log('CryptoCanvasPresentation.getUpdated');
    if (this.collections && this.collections[name]) {
      const item = this.collections[name].getUpdated(ids, ts);
      if (item) {
        return this.mappers[name].mapItem(this.template, item, this.manifest);
      }
    }
  }

  getNew(name) {
    log('CryptoCanvasPresentation.getNew');
    if (this.collections && this.collections[name] && this.collections[name].getNew) {
      const item = this.collections[name].getNew();
      if (item) {
        return this.mappers[name].mapItem(this.template, item, this.manifest);
      }
    }
  }

  hasNew(name) {
    log('CryptoCanvasPresentation.hasNew');
    if (this.collections && this.collections[name] && this.collection[name].hasNew) {
      return this.collections[name].hasNew();
    }
  }

  hasOld(name) {
    log('CryptoCanvasPresentation.hasOld');
    if (this.collections && this.collections[name] && this.collection[name].hasOld) {
      return this.collections[name].hasOld();
    }
  }

  getOld(name) {
    log('CryptoCanvasPresentation.getOld');
    if (this.collections && this.collections[name] && this.collections[name].getOld) {
      return this.collections[name].getOld();
    }
  }

  destroy() {
    log('CryptoCanvasPresentation.destroy');
    if (this.slide) {
      this.slide.destroy();
    }
    CCPresentation.prototype.destroy.apply(this, arguments);
  }

  stop() {
    CCPresentation.prototype.stop.apply(this, arguments);
    log('CryptoCanvasPresentation.stop');
    if (this.slide) {
      this.slide.stop();
    }
  }
}
