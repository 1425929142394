import {
  CCFadeBox,
  CCImageBox,
} from 'private/cc'

const loader = createLoader()

function createLoader() {
  const loader = new CCFadeBox('loader', {})
    .setIntro({fade: {duration: 0.5}})
    .setOutro({fade: {duration: 0.7, delay: 1.0}})
    .createNode()
    .appendTo(document.body)

  new CCImageBox('loader-img', {
    width: 220,
    height: 110,
    left: 'center',
    top: 'center',
    image: {
      width: 220,
      height: 110,
      size: 'contain',
      url: 'static/socialcanvas/loader.svg'
    }
  }, loader).createNode().appendTo(loader)

  new CCFadeBox('version-tag', {
    bottom: 10,
    height: 'auto',
    width: '100%',
    content: '1.0.9',
    style: 'font-size: 20px; color: white; text-align: center; opacity: 0.5;'
  }, loader).createNode().appendTo(loader)

  return loader
}

export function showLoader() {
  if (loader) {
    loader.show()
  }
}

export function hideLoader() {
  if (loader) {
    loader.hide()
  }
}

export default loader
